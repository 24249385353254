<template>
  <div class="cityAgency-container">
    <Top></Top>
    <Fix></Fix>
    <div class="cityAgency-container-top">
      <div>
        <div class="cityAgency-div1">
          <img :src="require('/static/images/guolao.png')"  alt="">
        </div>
        <div class="cityAgency-div2"></div>
        <div class="cityAgency-div3">城市代理人</div>
      </div>
    </div>
    <div class="cityAgency-center">
      <div class="cityAgency-container-middle-box-collapse">
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <img class="cityAgency-container-middle-box-collapse-img" :src="require('/static/images/system.png')" alt="">
              <span class="cityAgency-container-middle-box-collapse-span">城市代理人</span>
            </template>
            <router-link class="cityAgency-container-middle-box-collapse-personal" tag="div" to="/cityAgency/citysetPrice">
              设置服务价格
            </router-link>
            <router-link class="cityAgency-container-middle-box-collapse-personal" tag="div" to="/cityAgency/cityInvite">
              邀请加入
            </router-link>
            <router-link class="cityAgency-container-middle-box-collapse-personal" tag="div" to="/cityAgency/cityOrder">
              展业订单
            </router-link>
            <router-link class="cityAgency-container-middle-box-collapse-personal" tag="div" to="/cityAgency/billAll">
              账单总览
            </router-link>
          </el-collapse-item>

        </el-collapse>
      </div>
      <router-view></router-view>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Top from '@/components/ui/top.vue';
import Fix from '@/components/ui/fix.vue';
import Foot from '@/components/ui/foot.vue';


export default {
  name: 'cityAgency',
  components: {
    Top,
    Foot,
    Fix
  },
  data() {
    return {
      activeNames: ['1'],
    };
  },
  computed:{
    
  },
  methods: {
    
    
  },
  mounted() {

    }
}

</script>

<style scoped>
.cityAgency-container {
  background-color: #fafafa;
  z-index: 2;
  overflow: hidden;
}

.router-link-active {
  color: #EB5E00 !important;
}

/*.cityAgency-container > div:nth-child(5){*/
/*  min-height: calc(100vh - 508px);*/
/*}*/
.cityAgency-container-top {
  width: 100%;
  box-shadow: 0 4px 4px 0 rgba(231, 231, 231, 0.5);
  height: 96px;
  background: #ffffff;
  margin-bottom: 4px;
}

.cityAgency-container-top > div {
  width: 1000px;
  margin: 0 auto;
  height: 96px;
}

.cityAgency-div1 {
  width: 120px;
  height: 45px;
  margin-top: 23px;
  margin-right: 20px;
}

.cityAgency-div1 > img {
  width: 120px;
    height: 45px;
    margin-top: 3px;
}

.cityAgency-div2 {
  width: 2px;
  height: 36px;
  background-color: #EB5E00;
  margin: 30px 18px 0 0;
}

.cityAgency-div3 {
  font-size: 24px;
  margin-top: 31px;
  color: #EB5E00;
}

.cityAgency-container-top > div > div {
  float: left;
}

.cityAgency-center {
  width: 1160px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
}

.cityAgency-container-middle-box-collapse {
  width: 170px;
  height: calc(100vh - 416px);
  background: #fff;
  margin: 12px 0 0;
}

.cityAgency-center >>> .el-collapse-item__wrap {
  border-color: white;
}

.cityAgency-container-middle-box-collapse-center {
  font-size: 22px;
  font-weight: 100;
  margin: 0 0 14px 65px;
  color: #999999;
}

.cityAgency-container-middle-box-collapse-img {
  margin-right: 11px;
  margin-left: 27px;
  width: 14px;
  height: 14px;
}

.cityAgency-container-middle-box-collapse-span {
  font-size: 14px;
  color: #333333;
  font-weight: 700;
  white-space: nowrap;
}

.cityAgency-container-middle-box-collapse-personal {
  font-size: 14px;
  font-weight: 100;
  margin: 0px 0px 4px 54px;
  color: #666666;
  cursor: pointer;
}
</style>

